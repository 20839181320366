<template>
    <div>  
    <div v-if="bannerData.length>0" style="width: 1500px;margin: 0 auto;text-align: center;">
        <el-carousel indicator-position="outside" height="435px" ref="carousel">
        <el-carousel-item v-for="(item, index) in bannerData" :key="index" @click.native="linkTo(item.jumpUrl)">
            <img :src="`${item.imageUrl}`" style="cursor: pointer" alt="" />
        </el-carousel-item>
        </el-carousel>
    </div>
  
      <Footer @setPages="setPages" msg="right" ref="footPagesR"></Footer>
      <div class="goodsList" ref="goodslist" v-show="goodsList.length > 0">
			<div class="goodsList_div" v-for="(item, index) in goodsList" :key="index">
				<div class="goodsList_div_imgdiv">
					<div class="goodsList_div_imgdiv_icon">
						<img v-for="(fname, index) in item.iconArr" height="30" :src="fname" :key="index"  alt=""/>
					</div>
					<div class="goodsList_div_imgdiv_goodsImg">
						<img :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
							style="width: 350px" @click="clickImg(item)"  alt=""/>
					</div>

					<div @click="clickCollect(item)" class="goodsList_div_imgdiv_collect">
						<span v-if="item.collect_info"><img height="30" :src="hasCollect.yes"  alt=""/></span>
						<span v-else><img height="30" :src="hasCollect.not"  alt=""/></span>
					</div>
				</div>
				<div class="goodsList_div_bottom">
					<div class="prod_title">
						<span style="color: #000000; font-size: 14px; margin-right: 5px">
							{{ item.item_no }}
						</span>
						<i class="el-icon-document-copy" v-clipboard:copy="item.item_no" v-clipboard:success="onCopy"
							v-clipboard:error="onError"></i>
					</div>
					<div class="prod_title">
						<el-tooltip :content="item.item_en" placement="bottom" effect="light">
							<p style="color: #000000; font-size: 15px; margin-right: 5px">
								{{ item.GoodsName | cutstr }}
							</p>
						</el-tooltip>
					</div>
					<div class="prod_title" v-if="item.did_good==null">
						<div style="font-size: 14px; color: #000000">
							{{ $t("Wholesale price") }}:
						</div>
						<div style="font-size: 14px; color: #d92524; margin-left: 5px">
							{{ $t("Price symbol") }}{{ item.base_price.toFixed(2) }}
						</div>
						<div style="font-size: 14px; color: #000000; margin-left: 5px">
							{{ $t("RETALL PRICE") }}:
						</div>
						<div style="font-size: 14px; color: #d92524; margin-left: 5px">
							{{ $t("Price symbol") }}{{ item.sale_price.toFixed(2) }}
						</div>
					</div>

					<!-- 折扣区域展示 begin -->
					<div class="prod_title" v-if="item.did_good!=null">
						<div style="font-size: 14px; color: #000000;text-decoration:line-through">
							{{ $t("Wholesale price") }}:
						</div>
						<div style="font-size: 14px; color: #d92524; margin-left: 5px;text-decoration:line-through">
							{{$t('Price symbol')}}{{ item.base_price.toFixed(2) }}
						</div>
						<div style="font-size: 14px; color: #000000; margin-left: 5px">
							{{ $t("RETAIL PRICE") }}:
						</div>
						<div style="font-size: 14px; color: #d92524; margin-left: 5px">
							{{$t('Price symbol')}}{{ item.sale_price.toFixed(2) }}
						</div>
					</div>
					<div class="prod_title" v-if="item.did_good!=null">
						<div style="font-size: 14px; color: #000000;">
							{{ $t("Discount Price") }}:
						</div>
						<div style="font-size: 14px; color: #d92524; margin-left: 5px;">
							{{$t('Price symbol')}}{{ item.did_good.dis_price.toFixed(2) }}
						</div>
					</div>
					<!-- 折扣区域展示 end -->

					<div class="change_num">
						<el-input-number v-model="item.addNum" @change="handleChange" :step="item.purchase_spec"
							:min="item.purchase_spec" :max="item.us_num" v-if="item.is_moq === 0">
						</el-input-number>
            <el-input-number v-model="item.addNum" @change="handleChange" :step="item.purchase_spec"
							:min="item.purchase_spec" :max="item.us_num" v-else>
						</el-input-number>
					</div>


					<div class="prod_handle">
						<div class="prod_handle_add">
							<p class="prod_handle_title" v-if="item.us_num > 0 && item.display_flag == 1">
								<a @click="addCarts(item)">{{ $t("Add to Cart") }}</a>
								<a v-if="item.user_cart_info"
									style="margin-left: 8px">[{{ item.user_cart_info.num }}]</a>
								<a v-else style="margin-left: 8px">[0]</a>
								<i class="el-icon-delete" style="margin-left: 8px"
									@click="delteCartGoods(item.item_no)"></i>
							</p>
							<p class="prod_handle_not" v-else>
								{{ $t("coming soon") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
  
      <div class="notFindGoods" v-show="goodsList.length === 0">
        {{ $t("No products of this type found") }}
      </div>
      <Footer @setPages="setPages" ref="footPagesB"></Footer>
    </div>
  </template>
  <script>
import { GetGoodsList } from "@/api/goods/goods.js";
  
  import common from "@/common/mixins/common.js";
  import { AddToCartByGoods } from "@/api/carts/carts.js";
  import Footer from "@/views/web/allGoods/footer/index.vue";
  import banner from "@/api/web/home/banner";
  import { DeleteGoods2Carts } from "@/api/carts/carts";
import { addColect, delCollect } from "@/api/goods/collect";
  
  export default {
    mixins: [common],
    components: {
      Footer,
    },
    data() {
      return {
        num: [],
        goodsList: [],
        typeList: [],
        sku: "",
        queryData: {
            GtClsNo: "",
            priceBegin: "",
            priceEnd: "",
            page: 1,
            pageSize: 16,
        },
        hasCollect:{
            yes:require("@/assets/images/collect/2.png"),
            not:require("@/assets/images/collect/1.png")
        },
        bannerData: [
            {
            imageUrl: require("@/assets/images/banner/1.jpg"),
            jumpUrl: "/web/ipSeries?id=13",
            },
            {
            imageUrl: require("@/assets/images/banner/2.jpg"),
            jumpUrl: "/web/ipSeries?id=12",
            },
            {
            imageUrl: require("@/assets/images/banner/4.jpg"),
            jumpUrl: "/web/ipSeries?id=14",
            },
        ],
      };
    },
    watch: {
        $route(to, from) {
            //this.$router.go(0);
            this.getData();
        },
        sku() {
            this.getData();
        },
    },
    created() {
      this.getData();
      this.handleGetBanner();
    },
    methods: {
        //删除购物车处理
        delteCartGoods(sku) {
            DeleteGoods2Carts(sku).then(() => {
                this.$message.success("successfully deleted");
                this.getData();
                this.$emit("initCart");
            });
        },
        //点击收藏
        clickCollect(row) {
            let postData = {
                "sku": row.item_no,
            }
            if (row.collect_info){
                delCollect(row.item_no).then((res)=>{
                    row.collect_info = false
                    this.$message.success(this.$t('Cancel collection'))
                })
            } else {

                addColect(postData).then((res) => {
                    this.$message.success(this.$t('Successfully joined the collection'))
                    this.isJoin = true
                    row.collect_info = true
                }).catch((err) => {
                    let msg = "";
                    if (err.response.data.message == "已经加入收藏了") {
                        msg = this.$t('Has been added to the collection')
                    }
                    this.$message.error(msg)
                })
            }

        },
        //获取列表产品
        getData() {
            this.allGoods();
        },

      //复制商品条码
      onCopy: function() {
        this.$message.success("Product barcode copied to clipboard");
      },
      onError: function() {
        this.$message.success("Copy failed");
      },

      
      //加入购物车
      addCarts(row) {
        if ((row.addNum % row.purchase_spec) != 0) {
          this.$message.error(this.$t("Please change in multiples of specifications"));
          this.$nextTick(() => {
            row.addNum=row.purchase_spec;
          });
        } else {
          AddToCartByGoods(row.item_no, row.addNum, row.purchase_spec).then(() => {
            this.$emit("initCart");
            this.getData();
          });
        }
      },
        //================ 分页处理 begin
        //分页显示
        setFootPageShow(setValue) {
            this.$refs.footPagesB.setPages(
                setValue.page,
                setValue.total,
                setValue.page_size
            );
            this.$refs.footPagesR.setPages(
                setValue.page,
                setValue.total,
                setValue.page_size
            );
        },
        //初始化分页显示状况
        setFootPageShowInit() {
            this.$refs.footPages.setPages(1, 0, 1);
        },
        //================ 分页处理 end

        //设置翻页
        setPages(page) {
            this.queryData.page = page;
            this.getData();
            this.$nextTick(() => {
                document.body.scroll(0,410)
            })
        },
        //全部商品
        allGoods() {
            let urlQuery = this.$route.query;
            //请求参数设置 begin
            let queryData = {};
            queryData.RandomSort = 1 //设置每天随机排序	author@connor
            if (this.queryData.GtClsNo) {
                if (this.queryData.GtClsNo === "all") {
                    queryData.GtClsNo = "";
                } else {
                    queryData.GtClsNo = this.queryData.GtClsNo;
                }
            }
            queryData.page = this.queryData.page;
            queryData.pageSize = this.queryData.pageSize;
            queryData.Display = "y";
            queryData.noMoq = 1
            if (this.queryData.priceBegin && this.queryData.priceEnd) {
                queryData.priceBegin = this.queryData.priceBegin;
                queryData.priceEnd = this.queryData.priceEnd;
            }
            //queryData.HasShow = 1;
            if (urlQuery.name) {
                if (isNaN(urlQuery.name)) {
                    //字符查询
                    queryData.GtClsNo = "";
                    queryData.findName = urlQuery.name;
                    //queryData.HasShow = 0;
                    queryData.Display = "y";
                    queryData.noMoq = 0
                } else {
                    //数字查询
                    queryData.GtClsNo = "";
                    queryData.ItemNo = urlQuery.name;
                    queryData.HasShow = 0;
                    queryData.Display = "";
                    queryData.noMoq = 0
                }
            }
            queryData.heat = 1 //获取热销产品
            //请求参数设置 end
            GetGoodsList(queryData).then((res) => {
                let resApiData = res.data.data;
                if (resApiData.data) {
                    this.goodsList = this.filterGoods(res.data.data.data);
                    this.setFootPageShow(resApiData);
                } else {
                    this.goodsList = [];
                    this.setFootPageShowInit();
                }
            });
        },
			//设定一个添加购物车的数据，用来绑定添加购物车的数量
			filterGoods(goodsData) {
				let resData = goodsData;
				for (let i = 0; i < resData.length; i++) {
					resData[i].iconArr = resData[i].file_type_icon_list.split(",");

					//判断添加所属
					if (resData[i].stock_num >= resData[i].purchase_spec) {
						resData[i].addNum = resData[i].purchase_spec;
					} else {
						resData[i].addNum = resData[i].us_num;
						resData[i].purchase_spec = 1;
					}
					//语言判断
					if (this.$i18n.locale == "zh-CN") {
						resData[i].GoodsName = resData[i].item_name;
					} else {
						resData[i].GoodsName = resData[i].item_en;
					}
				}
				return resData;
			},
  
      handleChange(value) {
        console.log(value)
      },
      //跳转到详情页
      clickImg(row) {
        this.$router.push({
          path: "/web/goodsDetail",
          query: {
            id: row.item_no,
          },
        });
      },
      //获取banner图片
      handleGetBanner() {
        const typeid=99
        banner.getBanner(typeid).then((res) => {
            this.bannerData=res.data.data;
        });
      },
  

  
    },
  };
  </script>

<style lang="scss" scoped>
	.goodsList {
		width: 1496px;
		margin: 0 auto;
		background-color: #f5f8fa;
		display: grid;
		grid-template-columns: 350px 350px 350px 350px;
		grid-row-gap: 32px;
		grid-column-gap: 32px;

		&_div {
			// background: yellow;
			border: 1px solid #ccc;

			&_imgdiv {
				min-height: 350px;
				position: relative;

				//图标
				&_icon {
					position: absolute;
					top: 2px;
					right: 2px;

					img {
						border: 1px solid #ccc;
						margin-left: 3px;
					}
				}

				&_msg {
					width: 180px;
					height: 20px;
					background-color: #f5f8fa;
					position: relative;
					margin: -30px 0 0 160px;
					display: flex;
					align-items: center;
					justify-content: center;

					p {
						text-align: center;
						margin-left: 5px;
					}
				}

				//商品图标
				&_goodsImg {
					cursor: pointer;
					text-align: center;
					border-bottom: 1px solid #ccc;
					min-height: 350px;
				}
				//收藏
				&_collect{
					position: absolute;
					top: 2px;
					left: 2px;
				}
			}

			//文字按钮样式
			&_bottom {
				text-align: center;
				margin-top: 5px;
			}
		}
	}

	.prod_title {
		width: 350px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.change_num {
		//width: 356px;
		height: 37px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.prod_handle {
		//width: 356px;
		height: 40px;
		background-color: #ddedf0;
		margin-top: 5px;

		&_add {
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
		}

		&_title {
			color: #218da0;
			font-size: 16px;
			border-bottom: 1px solid #218da0;
		}

		&_not {
			color: red;
			font-size: 16px;
		}
	}

	.notFindGoods {
		width: 90%;
		margin: 0 auto;
		text-align: center;
		padding: 20px;
	}

	.marker {
		width: 10px;

		height: 10px;

		border: 1px solid #218da0;

		border-radius: 100px;

		background-color: #218da0;

		opacity: 0.5;
	}
</style>
  